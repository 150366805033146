.filterBox {
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 30px;
  max-width: 1380px;
  margin: 0 auto;
  padding: 20px 0 16px;

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #8c9196;
    white-space: nowrap;
  }

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin-left: 9px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;

    li {
      white-space: nowrap;
      cursor: pointer;
      margin-right: 5px;
      padding: 4px 8px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #8c9196;

      &.active {
        background: #008060;
        border: 1px solid #edeeef;
        box-sizing: border-box;
        border-radius: 4px;
        color: #ffffff;
      }

      &:hover {
        background: #015843;
        color: #fff;
        border-radius: 4px;
      }
    }
  }
}

@mixin dashCard($bgColor: #fff) {
  padding: 16px;
  box-shadow: 0px 2px 4px rgba(184, 184, 184, 0.25);
  border-radius: 8px;
  background-color: $bgColor;
  margin-bottom: 16px;
}
.verifyCard {
  @include dashCard(#fff);
  border: 1px solid #d2d5d8;
  padding: 18px 24px;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 4px;
    }
    button {
      padding: 2px 8px;
      background: #f1f1f1;
      border-radius: 4px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: #fd5749;
      border: none;
      outline: none;
      &[data-status="success"] {
        color: #00A47C;
      }
    }
  }
  hr {
    border: 1px solid #e4e5e7;
    margin: 12px 0;
  }
  .steps {
    position: relative;
    .upgrade {
      &::before {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        content: "";
        width: 100%;
        height: 100%;
        filter: blur(2px);
        z-index: 10;
      }
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        z-index: 50;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #347c84;
        padding: 8px 12px;
        cursor: pointer;
        background: #ffffff;
        border: 1px solid #aeb4b9;
        box-shadow: 0px 2px 7px rgba(33, 33, 33, 0.25);
        border-radius: 4px;
      }
    }
    ul {
      margin: 20px 0;
      padding: 0;
      li {
        list-style: none;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h5 {
          display: flex;
          align-items: center;
          svg {
            margin-right: 14px;
          }
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #000000;
        }
        .cta {
          display: flex;
          align-items: center;
          .disabled {
            color: #008060;
            cursor: not-allowed;
            opacity: 0.5;
            svg {
              fill: #008060;
            }
          }
          button {
            background: none;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #008060;
            display: flex;
            align-items: center;
            border: 0;
            outline: 0;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
            &[disabled="true"] {
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #2c6ecb;
      cursor: pointer;
    }
  }
  .pending {
    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #8c9196;
    }
  }
  .success {
    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #8c9196;
      margin: 0;
    }
    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      color: #000000;
      margin-bottom: 10px;
    }
    h5 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #2c6ecb;
      margin: 0;
    }
  }
  h6 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8c9196;
  }
}
.dashboard {
  $font-main: "Inter";
  display: grid;
  padding: 0 0 30px 0;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 16px;
  max-width: 1380px;
  margin: 0 auto;
  @media all and (max-width: 1400px) {
    grid-template-columns: 1.5fr 1fr;
    max-width: 100%;
  }
  @media all and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
  .cardLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100px;
  }

  .main {
    .btnMain {
      background: #ffffff;
      min-width: 120px;
      border-radius: 12px;
      padding: 8px 12px;
      font-family: $font-main;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: #000000;
      display: flex;
      align-items: center;
      border: 0;
      outline: 0;
      cursor: pointer;
      &:hover {
        background: #d5cdcd;
      }

      svg {
        margin-left: 14px;
      }
    }

    .welcomeCard {
      @include dashCard(#fff);
      border: 1px solid #d2d5d8;
      h2 {
        font-family: $font-main;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
      p {
        font-family: $font-main;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #8c9196;
        margin: 4px 0 8px 0;
      }
      h3 {
        font-family: $font-main;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #2c6ecb;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .sendingCounts {
      @include dashCard(#fff);
      border: 1px solid #d2d5d8;
      min-height: 123px;
      .parent {
        display: flex;
        @media all and (max-width: 680px) {
          flex-wrap: wrap;
          .right {
            width: 100% !important;
            margin-top: 25px !important;
            padding-left: 0 !important;
          }
        }
        .left {
          min-width: 170px;
          h3 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            color: #000000;
          }
          .count {
            display: flex;
            align-items: center;
            margin-top: 12px;
            h4 {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 26px;
              line-height: 24px;
              /* identical to box height, or 92% */

              color: #000000;
              margin-right: 12px;
            }
            h5 {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 150%;
              /* identical to box height, or 18px */

              /* Text/Disabled */

              color: #8c9196;
            }
          }
        }
        .right {
          padding-left: 20px;
          width: calc(100% - 130px);
          .progress {
            height: 9px;
            border-radius: 4px;
            border: 1px solid #f7ebeb;
            width: 100%;
            display: flex;
            align-items: center;
            span {
              display: block;
              transition: all 0.5s;
              :last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              }

              height: 100%;
            }
            .ct1 {
              background-color: #008060;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
            .ct2 {
              background-color: #b98900;
              // border-radius: 0 4px 4px 0;
            }
            .ct3 {
              background-color: #d9d9d9;
              border-radius: 0 4px 4px 0;
            }
          }
          .counts {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            .ctc1 {
              span {
                background: #008060;
              }
            }
            .ctc2 {
              span {
                background: #b98900;
              }
            }
            .ctc3 {
              span {
                background: #d9d9d9;
              }
            }
            .countItem {
              display: flex;
              align-items: center;
              margin-right: 5px;
              &:last-child {
                margin-right: 0;
              }
              @media all and (max-width: 600px) {
                flex-wrap: wrap;
                h6 {
                  margin-top: 10px;
                }
              }
              span {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                display: inline-block;
              }
              h5 {
                margin: 0 8px;
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                white-space: nowrap;

                color: #000000;
              }
              h6 {
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                /* identical to box height, or 21px */

                /* Text/Disabled */

                color: #8c9196;
              }
            }
          }
        }
      }
      .bottom {
        margin-top: 27px;
        h6 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          cursor: pointer;
          color: #2c6ecb;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .sendPastOrders {
      @include dashCard(#fff);
      border: 1px solid #c9ccd0;
      h5 {
        display: flex;
        align-items: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #202223;
        margin-bottom: 4px;
        svg {
          margin-right: 11px;
        }
      }
      h6 {
        margin-left: 35px;
        display: flex;
        align-items: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #347c84;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        svg {
          margin-left: 17px;
        }
      }
    }
    .limitCard {
      @include dashCard(#d82c0d);
      .title {
        font-family: $font-main;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
      .desc {
        font-family: $font-main;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
        margin: 4px 0 8px 0;
      }
    }
    .enableCard {
      @include dashCard(#b98900);
      h2 {
        font-family: $font-main;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
      p {
        font-family: $font-main;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
        margin: 4px 0 8px 0;
      }
      .btnCard {
        @extend .btnMain;
      }
      .btnLdr {
        @extend .btnMain;
        line-height: 0;

        justify-content: center;

        svg {
          margin: 0;
        }
      }
    }
    .installCard {
      @include dashCard(#054d47);
      h2 {
        font-family: $font-main;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
      p {
        font-family: $font-main;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
        margin: 4px 0 8px 0;
      }
      button {
        @extend .btnMain;
      }
    }
    .orderCard {
      @include dashCard(#d82c0d);
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        font-family: $font-main;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
      button {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        display: flex;
        align-items: center;
        background-color: inherit;
        border: 0;
        outline: none;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        svg {
          margin-left: 14px;
        }
      }
    }
    .expiredOrder {
      @include dashCard(#fff);
      border: 1px solid #c9ccd0;
      .actionItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        &:nth-child(2) {
          margin-top: 20px;
        }

        h5 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #202223;
          white-space: nowrap;
          svg {
            margin-right: 11px;
          }
        }
        h6 {
          display: flex;
          align-items: center;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #347c84;
          cursor: pointer;
          svg {
            margin-left: 17px;
          }
          &:last-child {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .countCards {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @media all and (max-width: 620px) {
        grid-template-columns: 1fr;
      }
      grid-gap: 16px;
      .countBox {
        background: #ffffff;
        border: 1px solid #c9ccd0;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(184, 184, 184, 0.25);
        border-radius: 8px;
        padding: 16px;
        min-height: 127px;
        h2 {
          font-family: $font-main;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
        .count {
          margin: 12px 0;
          display: flex;
          align-items: center;
          h1 {
            font-family: $font-main;
            font-style: normal;
            font-weight: 600;
            font-size: 26px;
            line-height: 24px;
            color: #000000;
          }
          small {
            font-family: $font-main;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            margin-left: 12px;
            color: #8c9196;
          }
        }
        h3 {
          font-family: $font-main;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #2c6ecb;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .aside {
  }
}
